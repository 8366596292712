import React from "react"

import Navi from "./navi"
import Header from "./header"
import Footer from "./footer"

import MailingList from "../Content/MailingList"
import ShedApp from "../Content/ShedApp"
import Social from "../Content/Social"

import "./index.scss"

const Layout = ({ children, location }) => (
  <>
    <Header />
    <Navi location={location} />
    <main className="container">{children}</main>
    <div className="container">
      <div className="row">
        <MailingList />
        <ShedApp />
        <Social />
      </div>
    </div>
    <Footer />
  </>
)

export default Layout
