import React from "react"
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"

import "./navi.scss"

const auctionsAppLink = process.env.AUCTIONS_APP_LINK

const Navi = (props) => {
  const activeItem = (path) => {
    if (props.location.pathname === path) {
      return "nav-item active"
    } else {
      return "nav-item"
    }
  }

  return (
    <Navbar expand="md">
      <div className="container">
        <Navbar.Brand />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
          <ul className="navbar-nav nav-fill">
            <li className={activeItem("/")}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={auctionsAppLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Online Auctions
              </a>
            </li>
            <li className={activeItem("/upcoming-auctions")}>
              <Link to="/upcoming-auctions" className="nav-link">
                Upcoming Auctions
              </Link>
            </li>
            <li className={activeItem("/auction-info")}>
              <Link to="/auction-info" className="nav-link">
                Auction Info
              </Link>
            </li>
            <li className={activeItem("/fair-dinkum-sheds")}>
              <Link to="/fair-dinkum-sheds" className="nav-link">
                Garages &amp; Sheds
              </Link>
            </li>
            <li className={activeItem("/contact")}>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navi
