import React from "react"
import { Link } from "gatsby"
import Button from "../Button"

import scaLogo from "../../images/sca-logo.jpg"
import fdsLogo from "../../images/fds-logo.png"

import "./header.scss"

const Header = () => {
  return (
    <>
      <div className="header-top">
        <div className="container desktop">
          <div className="row">
            <div className="col-12">
              <span>
                Address: 205A Shellharbour Road, Kemblawarra, NSW - Phone (02)
                4275 1644
              </span>
            </div>
          </div>
        </div>
        <div className="container mobile">
          <div className="row">
            <div className="col-12">
              <a href="https://www.google.com/maps/place/South+Coast+Auctions/@-34.4931354,150.8907352,17z/data=!3m1!4b1!4m5!3m4!1s0x6b13171ba63d1ed3:0xebded0dbfed98a09!8m2!3d-34.4931354!4d150.8929292">
                <Button
                  btnStyle="sca-primary"
                  customClass="btn-margin-left"
                  icon="location-arrow"
                  iconPosition="left"
                >
                  Location
                </Button>
              </a>
              <a href="tel:0242751644">
                <Button
                  btnStyle="sca-primary"
                  customClass="btn-margin-left"
                  icon="phone-alt"
                  iconPosition="left"
                >
                  Phone
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 header">
            <Link to="/">
              <img
                src={scaLogo}
                alt="South Coast Auctions Logo"
                className="sca-logo"
              />
            </Link>
            <h1>South Coast Auctions</h1>
            <p>General &amp; Motor Vehicle Auctions</p>
            <a
              href="http://www.fairdinkumsheds.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={fdsLogo}
                alt="Fair Dinkum Sheds Logo"
                className="fds-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
