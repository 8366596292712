import React from "react"
import Button from "../../Button"

const MailingList = () => (
  <div className="col-md-4 text-center">
    <h5>Join our mailing list</h5>
    <form
      action="https://southcoastauctions.us2.list-manage.com/subscribe/post?u=c7b685e9ca4e85be51d1e6c08&amp;id=fa385c59b1"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <div className="form-group">
        <input
          type="text"
          name="FNAME"
          className="form-control"
          placeholder="first name"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="LNAME"
          className="form-control"
          placeholder="last name"
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="EMAIL"
          className="form-control"
          placeholder="email address*"
          required
        />
      </div>
      <div className="form-group">
        <Button
          btnStyle="sca-primary"
          customClass="btn-icon-right"
          icon="user-plus"
        >
          Subscribe
        </Button>
      </div>
    </form>
  </div>
)

export default MailingList
