import React from "react"

import facebookLogo from "../../../images/social/facebook-icon.png"
import instagramLogo from "../../../images/social/instagram-logo.png"

import "./index.scss"

const Social = () => {
  return (
    <div className="col-md-4 text-center">
      <h5>We are Social</h5>
      <div className="row">
        <div className="col">
          <a
            href="https://www.facebook.com/southcoastauctionswollongong/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebookLogo}
              alt="Facebook Logo"
              className="social-icon"
            />
          </a>
          <a
            href="https://www.instagram.com/southcoastauctions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramLogo}
              alt="Instagram Logo"
              className="social-icon"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Social
