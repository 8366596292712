import React from "react"
import { Link } from "gatsby"
import logo from "../../images/sca-grey.png"
import "./footer.scss"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <img src={logo} alt="" />
        </div>
        <div className="col-md-4">
          <p>
            P: (02) 4275 1644
            <br />
            F: (02) 4276 1810
          </p>
          <p>
            205a Shellharbour Road
            <br />
            Kemblawarra, NSW, 2505
          </p>
        </div>
        <div className="col-md-4">
          <nav className="nav flex-column">
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className="nav-link">
              Privacy Policy
            </Link>
          </nav>
        </div>
      </div>
      <div className="row tag">
        <div className="col-12 text-center">
          <span>Site by </span>
          <a
            href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=south_coast_auctions"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSS Digital
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
