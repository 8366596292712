import React from "react"
import Icon from "../Icon"

import "./index.scss"

const Button = ({
  disabled,
  btnStyle,
  customClass,
  type,
  children,
  icon,
  iconPosition,
  callBack,
}) => {
  if (disabled === true) {
    return (
      <button
        className={`btn btn-${btnStyle} ${customClass}`}
        type={type}
        disabled
      >
        {`${children} `}
        <Icon name="spinner" spin={true} />
      </button>
    )
  } else if (iconPosition === "left") {
    return (
      <button
        className={`btn btn-${btnStyle} ${customClass}`}
        type={type}
        onClick={callBack}
      >
        {icon ? <Icon name={icon} /> : ""}
        {`${children} `}
      </button>
    )
  } else {
    return (
      <button
        className={`btn btn-${btnStyle} ${customClass}`}
        type={type}
        onClick={callBack}
      >
        {`${children} `} {icon ? <Icon name={icon} /> : ""}
      </button>
    )
  }
}

export default Button
