import React from "react"

import "./index.scss"

import shedsApp from "../../../images/sheds/sheds-app.jpg"
import webApp from "../../../images/sheds/web-app.png"
import appStore from "../../../images/sheds/app-store.png"
import googlePlay from "../../../images/sheds/google-play.png"

const ShedApp = () => {
  return (
    <div className="col-md-4 text-center">
      <h5>Design your own shed</h5>
      <img src={shedsApp} className="sheds-app" alt="Sheds App" />
      <div className="row">
        <div className="col-6 text-center">
          <a
            href="http://designer.fairdinkumsheds.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={webApp} className="app-icon" alt="Launch Web App" />
          </a>
        </div>
        <div className="col-6 text-center">
          <a
            href="https://apps.apple.com/au/app/fair-dinkum-sheds-designer/id644134588?ign-mpt=uo%3D4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStore} className="app-icon" alt="Apple App Store" />
          </a>
        </div>
        <div className="col-6 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.fds.sheddesigner"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlay}
              className="app-icon"
              alt="Google Play Store"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ShedApp
